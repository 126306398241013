<template>
  <v-row>
    <v-col cols="8">
      <section>
        <v-row>
          <v-col cols="12">
            <v-card outlined :loading="loading">
              <v-card-title class="d-flex align-center py-2">
                <div class="d-flex">
                  <h5>{{ campaignName ? campaignName : "Loading" }}</h5>
                </div>
                <v-spacer></v-spacer>
                <template>
                  <v-chip
                    v-if="update === null"
                    color="grey lighten-5"
                    text-color="grey"
                    class="font-weight-bold"
                    small
                  >
                    <v-icon x-small left>mdi-circle</v-icon>Incomplete
                  </v-chip>
                  <v-chip
                    v-else-if="update == 'Active'"
                    color="green lighten-5"
                    text-color="success"
                    class="font-weight-bold"
                    small
                  >
                    <v-icon x-small left>mdi-circle</v-icon>{{ update }}
                  </v-chip>
                  <v-chip
                    v-else-if="update == 'Paused'"
                    color="grey lighten-5"
                    text-color="grey"
                    class="font-weight-bold"
                    small
                  >
                    <v-icon x-small left>mdi-circle</v-icon>{{ update }}
                  </v-chip>
                  <v-chip
                    v-else-if="update == 'In Review'"
                    color="orange lighten-5"
                    text-color="warning"
                    class="font-weight-bold"
                    small
                  >
                    <v-icon x-small left>mdi-circle</v-icon>{{ update }}
                  </v-chip>
                  <v-chip
                    v-else-if="update == 'Completed'"
                    color="cyan lighten-5"
                    text-color="cyan"
                    class="font-weight-bold"
                    small
                  >
                    <v-icon x-small left>mdi-circle</v-icon>{{ update }}
                  </v-chip>
                  <v-chip
                    v-else
                    color="red lighten-5"
                    text-color="error"
                    class="font-weight-bold"
                    small
                  >
                    <v-icon x-small left>mdi-circle</v-icon>{{ update }}
                  </v-chip>
                </template>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-card flat color="grey lighten-5 mb-4">
                  <v-card-subtitle class="overline grey--text lighten-5 py-2">
                    Campaign Details</v-card-subtitle
                  >
                  <v-card-text>
                    <v-row>
                      <v-col md="4" cols="6">
                        <h5 class="font-weight-regular text-uppercase">Client</h5>
                        <p class="black--text font-weight-bold">
                          {{ advertiser ? advertiser.client_name : "N/A" }}
                        </p>
                      </v-col>
                      <v-col md="4" cols="6">
                        <h5 class="font-weight-regular text-uppercase">
                          Campaign Objective
                        </h5>
                        <p class="black--text font-weight-bold text-capitalize">
                          {{ campaignObjective ? campaignObjective : "N/A" }}
                        </p>
                      </v-col>
                      <v-col md="4" cols="6">
                        <h5 class="font-weight-regular text-uppercase">Campaign Type</h5>
                        <p class="black--text font-weight-bold text-capitalize">
                          {{ campaignType ? campaignType.name : "N/A" }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card flat color="grey lighten-5 mb-4">
                  <v-card-subtitle class="overline grey--text lighten-5 py-2">
                    Campaign Duration</v-card-subtitle
                  >
                  <v-card-text>
                    <v-row>
                      <v-col md="4" cols="6">
                        <h5 class="font-weight-regular text-uppercase">Start Date</h5>
                        <p class="black--text font-weight-bold">
                          {{ startDate }}
                        </p>
                      </v-col>
                      <v-col md="4" cols="6">
                        <h5 class="font-weight-regular text-uppercase">End Date</h5>
                        <p class="black--text font-weight-bold">
                          {{ endDate }}
                        </p>
                      </v-col>
                      <v-col md="4" cols="6">
                        <h5 class="font-weight-regular text-uppercase">Duration</h5>
                        <p class="black--text font-weight-bold">{{ _duration }} Days</p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card flat color="grey lighten-5 mb-4">
                  <v-card-subtitle class="overline grey--text lighten-5 py-2">
                    Campaign Pricing</v-card-subtitle
                  >
                  <v-card-text>
                    <v-row>
                      <v-col md="3" cols="6">
                        <h5 class="font-weight-regular text-uppercase">Pricing Model</h5>
                        <p class="black--text font-weight-bold">
                          {{ pricingModel }}
                        </p>
                      </v-col>
                      <v-col md="3" cols="6">
                        <h5 class="font-weight-regular text-uppercase">Budget</h5>
                        <p class="black--text font-weight-bold">
                          ${{ totalBudget ? totalBudget : 0 }}
                        </p>
                      </v-col>
                      <v-col md="3" cols="6">
                        <h5 class="font-weight-regular text-uppercase">Impressions</h5>
                        <p class="black--text font-weight-bold">
                          {{ impressions ? impressions.toLocaleString() : 0 }}
                        </p>
                      </v-col>
                      <v-col md="3" cols="6">
                        <h5 class="font-weight-regular text-uppercase">ECPM</h5>
                        <p class="black--text font-weight-bold">
                          {{ parseFloat(CeCPM) }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card flat color="grey lighten-5 ">
                  <v-card-subtitle class="overline grey--text lighten-5 py-2">
                    Campaign URL</v-card-subtitle
                  >
                  <v-card-text>
                    <v-row>
                      <v-col md="4" cols="6">
                        <h5 class="font-weight-regular text-uppercase">
                          Click Through URl
                        </h5>
                        <p class="font-weight-bold">
                          <a :href="click_through_URL" target="_blank" text>
                            {{ clickThrough(click_through_URL) }}
                          </a>
                        </p>
                      </v-col>
                      <v-col md="4" cols="6">
                        <h5 class="font-weight-regular text-uppercase">
                          Cascade Click URL
                        </h5>
                        <p class="black--text font-weight-bold">
                          {{ cascade_click_url == 1 ? "True" : "False" }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card v-if="superAdmin" flat color="orange lighten-5 mt-4 ">
                  <v-card-subtitle class="overline grey--text lighten-5 py-2">
                    Campaign Targeting Options</v-card-subtitle
                  >
                  <v-card-text>
                    <v-row>
                      <v-col md="4" cols="6">
                        <h5 class="font-weight-regular text-uppercase">Location</h5>
                        <p class="black--text font-weight-bold">
                          {{ location ? location : "Kenya" }}
                        </p>
                      </v-col>
                      <v-col md="4" cols="6">
                        <h5 class="font-weight-regular text-uppercase">Exchanges</h5>
                        <p class="black--text font-weight-bold">
                          {{ selectedExchange(selected_exchanges) }}
                        </p>
                      </v-col>
                      <v-col md="4" cols="6">
                        <h5 class="font-weight-regular text-uppercase">Pretargets</h5>
                        <p class="black--text font-weight-bold">
                          {{ selected_pretargets ? selected_pretargets.name : "N/A" }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </section>
    </v-col>

    <v-col cols="4">
      <v-card
        outlined
        v-if="
          this.$route.name == 'view-dsp-campaign' ||
          this.$route.name == 'edit-dsp-campaign'
        "
        class="mb-5"
      >
        <v-card-title class="py-2"><h5>Campaign Progress</h5></v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="mb-5">
            <div class="d-flex justify-space-between">
              <h3 class="overline">Flight Pacing</h3>
              <strong>{{ Math.ceil(pacingValue) }}%</strong>
            </div>

            <v-progress-linear :value="pacingValue" rounded height="8" color="success">
            </v-progress-linear>
          </div>
          <v-card color="grey lighten-5" flat class="mb-4">
            <v-card-title class="display-1">
              {{ campaignImpressions.toLocaleString() }}
            </v-card-title>
            <v-card-subtitle class="overline grey--text lighten-5">
              Total Impressions
            </v-card-subtitle>
          </v-card>
          <v-card color="grey lighten-5" flat class="mb-4">
            <v-card-title class="display-1"> ${{ campaignSpend }} </v-card-title>
            <v-card-subtitle class="overline grey--text lighten-5">
              Total Spend
            </v-card-subtitle>
          </v-card>

          <!-- <span v-if="campaignType && campaignType.name == 'Awareness'">
            <v-card color="grey lighten-5" flat class="mb-4">
              <v-card-title class="display-1"> {{ campCPM }}% </v-card-title>
              <v-card-subtitle class="overline grey--text lighten-5">
                Avg. CPM
              </v-card-subtitle>
            </v-card>
          </span> -->
          <span v-if="campaignType && campaignType.name == 'Engagement'">
            <v-card color="grey lighten-5" flat class="mb-4">
              <v-card-title class="display-1"> {{ campViews }} </v-card-title>
              <v-card-subtitle class="overline grey--text lighten-5">
                Total Views
              </v-card-subtitle>
            </v-card>
            <v-card color="grey lighten-5" flat class="mb-4">
              <v-card-title class="display-1"> {{ campVTR }}% </v-card-title>
              <v-card-subtitle class="overline grey--text lighten-5">
                Avg. VTR
              </v-card-subtitle>
            </v-card>
          </span>

          <span v-if="campaignType && campaignType.name == 'Awareness'">
            <v-card color="grey lighten-5" flat class="mb-4">
              <v-card-title class="display-1"> {{ campCTR }}% </v-card-title>
              <v-card-subtitle class="overline grey--text lighten-5">
                Avg. CTR
              </v-card-subtitle>
            </v-card>
            <v-card v-if="!isLead " color="grey lighten-5" flat class="mb-4">
              <v-card-title class="display-1">
                {{ campClicks.toLocaleString() }}
              </v-card-title>
              <v-card-subtitle class="overline grey--text lighten-5">
                Total Clicks
              </v-card-subtitle>
            </v-card>
          </span>

          <span v-if="campaignType && campaignType.name == 'Conversion'">
            <v-card color="grey lighten-5" flat class="mb-4">
              <v-card-title class="display-1">
                {{ campLeads }}
              </v-card-title>
              <v-card-subtitle class="overline grey--text lighten-5">
                Total Leads
              </v-card-subtitle>
            </v-card>
            <v-card color="grey lighten-5" flat class="mb-4">
              <v-card-title class="display-1">
                {{ campCPL }}
              </v-card-title>
              <v-card-subtitle class="overline grey--text lighten-5">
                Avg. CPL
              </v-card-subtitle>
            </v-card>
            <!-- <v-card color="grey lighten-5" flat>
            <v-card-title class="display-1"> ${{ campCPC }} </v-card-title>
            <v-card-subtitle class="overline grey--text lighten-5">
              Avg. Cpc
            </v-card-subtitle>
          </v-card> -->
          </span>
        </v-card-text>
      </v-card>

      <!-- activity log -->
      <general-card v-if="superAdmin" class="mb-5">
        <v-card-title class="py-2">
          <h5>Activity Log</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-timeline :reverse="false" dense>
            <v-timeline-item small v-for="item in log" :key="item.id">
              <v-card class="elevation-2">
                <v-card-subtitle>
                  {{ item.description }} - {{ eventTime(item.created_at) }}
                </v-card-subtitle>
                <v-card-text>
                  <p>Causer: {{ item.properties.name }}</p>
                  <p>
                    Type: {{ item.properties.oldValues.type }} to
                    {{ item.properties.newValues.type }}
                  </p>
                  <p>
                    Budget: {{ item.properties.oldValues.budget }} to
                    {{ item.properties.newValues.budget }}
                  </p>
                  <p>
                    Start Time:
                    {{ eventTime(item.properties.oldValues.activate_time) }}
                    to
                    {{ eventTime(item.properties.newValues.activate_time) }}
                  </p>
                  <p>
                    End Time:
                    {{ eventTime(item.properties.oldValues.expire_time) }}
                    to
                    {{ eventTime(item.properties.newValues.expire_time) }}
                  </p>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </general-card>
    </v-col>
  </v-row>
</template>
<script>
import GeneralCard from "../Layouts/Cards/GeneralCard.vue";
import { dspCampaignMixin } from "../../mixins/dspCampaignMixin";

export default {
  mixins: [dspCampaignMixin],
  components: {
    GeneralCard,
  },
  props: {
    update: String,
    loading: Boolean,
  },
  methods: {
    clickThrough(string) {
      let clickthrough = string;

      if (clickthrough) {
        return clickthrough.substring(0, 30) + "...";
      }
    },
    selectedExchange(selected_exchanges) {
      if (selected_exchanges === "google") {
        return "Double Click";
      }

      return selected_exchanges;
    },
  },
  computed: {
    _duration() {
      return this.datediff(this.startDate, this.endDate);
    },
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
    pacingValue() {
      let value = (this.campImpressions / this.impressions) * 100;
      if (!this.superAdmin && value > 100) {
        value = 100;
      }
      if (!isFinite(value) || isNaN(value)) {
        value = 0;
      }
      return value;
    },
    campaignSpend() {
      let budget = this.totalBudget;
      if (budget == null) {
        budget = 0;
      }
      let value = this.campSpend;
      if (!this.superAdmin && value > budget) {
        value = budget;
      }
      if (!isFinite(value) || isNaN(value)) {
        value = 0;
      }
      return parseFloat(value).toFixed(2);
    },
    campaignImpressions() {
      let value = this.campImpressions;
      if (!this.superAdmin && this.campImpressions > this.impressions) {
        value = this.impressions;
      }
      if (!isFinite(value) || isNaN(value)) {
        value = 0;
      }
      return value;
    },
  },
  watch: {
    pacing_value() {
      this.pacingValue();
    },
  },
};
</script>
