<template>
  <div>
    <v-alert
      v-if="
        this.$route.query.from == 'view' ||
        this.$route.name == 'edit-dsp-campaign'
      "
      dense
      text
      type="success"
    >
      You're now editing <strong>{{ campaign.name }}</strong> campaign
    </v-alert>

    <v-alert
      v-if="this.$route.name == 'view-dsp-campaign'"
      dense
      text
      color="blue-grey"
    >
      <v-row align="center">
        <v-col class="grow"> Click on the edit button to update campaign</v-col>
        <v-col class="shrink">
          <v-btn
            v-if="this.$route.name == 'view-dsp-campaign'"
            @click="
              $router.push({
                name: 'edit-dsp-campaign',
                id: campaign.id,
                query: { from: 'view' },
              })
            "
            color="secondary"
            depressed
            small
          >
            Edit Campaign
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <general
      v-if="routeName === 'edit-dsp-campaign'"
      :dsp_campaign="campaign"
      :campaign_statistics="campaign_statistics"
      :log="activity"
      @emitCampaignBodyUpdate="updateCampaign($event)"
    />

    <campaign-view
      v-if="routeName === 'view-dsp-campaign'"
      :dsp_campaign="campaign"
      :loading="loading"
      :campaign_statistics="campaign_statistics"
      :log="activity"
      :update="update"
    />
  </div>
</template>

<script>
import General from "../../components/NewCampaign/General.vue";
import CampaignView from "../../components/NewCampaign/CampaignView.vue";

export default {
  data() {
    return {
      currentItem: "general",
      loading: false,
      update: null,
      error: null,
      activity: [],
      campaign: {},
      items: [
        {
          text: "Campaigns",
          disabled: false,
          href: "#/tradedesk/campaigns",
        },
        {
          text: this.breadCrumbText(),
          disabled: true,
          href: "#",
        },
      ],
    };
  },
  components: {
    General,
    CampaignView,
  },
  mounted() {
    this.getCampaignData();
  },
  methods: {
    breadCrumbText() {
      return this.$route.name == "edit-dsp-campaign"
        ? "Edit Campaign"
        : "View Campaign";
    },
    //Get Single Campaign
    async getCampaignData() {
      try {
        this.$store.commit("makingApiRequest", true);
        this.loading = true;
        await this.$store.dispatch(
          "dsp/getOneDspCampaign",
          this.$route.params.id
        );
        await this.$store.dispatch(
          "dsp/singleCampaignStatistics",
          this.$route.params.id
        );
        this.$store.commit("makingApiRequest", false);
        this.loading = false;
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
        this.loading = false;
      }
    },
    //Update Camapign
    async updateCampaign(req_body) {
      try {
        let body = {
          campaign_id: this.$route.params.id,
          req_body: req_body,
        };
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/updateDspCampaign", body);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Campaign updated successfully!",
          group: "success",
        });

        if (this.dsp_campaign) {
          await this.$router.push("/tradedesk/campaigns");
        }
      } catch (error) {
        this.$notify({
        title: "Sorry, there was a problem updating the campaign. Please try again later",
        group: "errors",
      });
        this.$store.commit("makingApiRequest", false);
      }
    },

    updateDataStore() {},
  },
  computed: {
    dsp_campaign() {
      return this.$store.state.dsp.dsp_campaign;
    },
    campaign_statistics() {
      return this.$store.state.dsp.campaign_statistics;
    },
    routeName() {
      return this.$route.name;
    },
  },
  watch: {
    dsp_campaign() {
      this.campaign = this.dsp_campaign.campaign;
      this.activity = this.dsp_campaign.activity;
      this.update = this.dsp_campaign.status;
    },
    $route(to, from) {
      if (
        from.name === "view-dsp-campaign" ||
        to.name === "edit-dsp-campaign"
      ) {
        this.getCampaignData();
      }
    },
  },
};
</script>

<style lang="sass"></style>
