var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      this.$route.query.from == 'view' ||
      this.$route.name == 'edit-dsp-campaign'
    )?_c('v-alert',{attrs:{"dense":"","text":"","type":"success"}},[_vm._v(" You're now editing "),_c('strong',[_vm._v(_vm._s(_vm.campaign.name))]),_vm._v(" campaign ")]):_vm._e(),(this.$route.name == 'view-dsp-campaign')?_c('v-alert',{attrs:{"dense":"","text":"","color":"blue-grey"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(" Click on the edit button to update campaign")]),_c('v-col',{staticClass:"shrink"},[(this.$route.name == 'view-dsp-campaign')?_c('v-btn',{attrs:{"color":"secondary","depressed":"","small":""},on:{"click":function($event){return _vm.$router.push({
              name: 'edit-dsp-campaign',
              id: _vm.campaign.id,
              query: { from: 'view' },
            })}}},[_vm._v(" Edit Campaign ")]):_vm._e()],1)],1)],1):_vm._e(),(_vm.routeName === 'edit-dsp-campaign')?_c('general',{attrs:{"dsp_campaign":_vm.campaign,"campaign_statistics":_vm.campaign_statistics,"log":_vm.activity},on:{"emitCampaignBodyUpdate":function($event){return _vm.updateCampaign($event)}}}):_vm._e(),(_vm.routeName === 'view-dsp-campaign')?_c('campaign-view',{attrs:{"dsp_campaign":_vm.campaign,"loading":_vm.loading,"campaign_statistics":_vm.campaign_statistics,"log":_vm.activity,"update":_vm.update}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }